import { Button } from '@mui/material';
import { ConfirmActionModal } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export type CancelReviewButtonProps = {
  disabled?: boolean;
};

export const CancelReviewButton: React.FC<CancelReviewButtonProps> = ({ disabled }) => {
  const navigate = useNavigate();
  const [ open, setOpen ] = useState(false);

  return (
    <>
      <ConfirmActionModal
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={() => navigate(ROUTING_CONFIG.inventoryBatches)}
        title="Cancel Review"
        confirmButtonText="Cancel Review"
        cancelButtonText="Go Back"
        text="Are you sure you want to cancel review? This action cannot be undone."
      />
      <Button variant="outlined" onClick={() => setOpen(true)} disabled={disabled}>Cancel Review</Button>
    </>
  );
};
