import { ReviewsOutlined } from '@mui/icons-material';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import { AddEventFeedbackModal, EventFeedbackCard, SummativeEventFeedback } from 'components/Event/components';
import { useEventContext } from 'components/Event/event.context';
import { useCurrentUser } from 'contexts';
import { useEventFeedback } from 'queries/eventFeedback';
import { QUERY_KEY } from 'queries/query-keys';
import React, { useState } from 'react';

export const EventPreviewContentFeedback = () => {
  const { event } = useEventContext();
  const { getIsMe } = useCurrentUser();
  const { data: eventFeedback = [] } = useEventFeedback(event._id);
  const [ addFeedbackOpen, setAddFeedbackOpen ] = useState(false);
  const addFeedbackDisabled = eventFeedback.some(feedback => getIsMe(feedback.user._id));

  return (
    <Box p={2} height="calc(100vh - 171px)">
      {addFeedbackOpen && <AddEventFeedbackModal onClose={() => setAddFeedbackOpen(false)} eventId={event._id} invalidateQueriesHandler={queryClient => queryClient.invalidateQueries({ queryKey: QUERY_KEY.EVENT_FEEDBACK(event._id) })} />}
      <SummativeEventFeedback feedback={eventFeedback} />
      <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
        <Tooltip title={addFeedbackDisabled ? 'You have already left the feedback for this event. You can edit your form below.' : undefined}>
          <Box component="span">
            <Button
              variant="contained"
              onClick={() => setAddFeedbackOpen(true)}
              disabled={addFeedbackDisabled}
              endIcon={<ReviewsOutlined fontSize="small" />}
            >
              Leave Feedback
            </Button>
          </Box>
        </Tooltip>
      </Box>
      <Stack gap={1}>
        {eventFeedback.map((feedback) => <EventFeedbackCard key={feedback._id} eventFeedback={feedback} />)}
      </Stack>
    </Box>
  );
};
