import { EnumHelpers } from 'helpers/enums/types';

export const enum DashboardPageSection {
  EVENTS_MANAGEMENT = 'events_management',
  PRODUCTION = 'production',
  EVENTS = 'events',
}

// Events

export const enum DashboardPageEventsOverviewSection {
  MY_EVENTS = 'my_events',
  PENDING_REQUESTS = 'pending_requests',
  CALENDAR = 'calendar',
}

export const dashboardPageEventsOverviewSectionEnumHelpers: EnumHelpers<DashboardPageEventsOverviewSection> = {
  enumValues: [
    DashboardPageEventsOverviewSection.MY_EVENTS,
    DashboardPageEventsOverviewSection.PENDING_REQUESTS,
    // DashboardPageEventsOverviewSection.CALENDAR,
  ],
  getLabel: (value) => {
    switch (value) {
      case DashboardPageEventsOverviewSection.MY_EVENTS:
        return 'My Events';
      case DashboardPageEventsOverviewSection.PENDING_REQUESTS:
        return 'Pending Requests';
      case DashboardPageEventsOverviewSection.CALENDAR:
        return 'Calendar';
      default:
        return 'Unknown';
    }
  },
  getColor: () => 'unknown'
};