import { authAxiosRequest } from 'api/axios';
import { GetEventDateAddStaffUserOptions } from '../eventDate';
import { Event, EventAcceptanceStatusEnum, EventApplicationStatusEnum, EventDate, EventDateStaff, EventForResourceTypeEnum, EventLodging, EventParticipationStatusEnum, EventToDo, Place, PlaceMatrix, Team, User } from 'api/resources';
import { GetEventsResponse } from '../event';
import { PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';
import { GetUserAvailabilityResponse } from '../user';
import { UnknownEnum } from 'types';
import { GetInventoryBatchesResponse } from '../inventoryBatch';

export type GetStaffSchedulingForEventInput = {
  eventId: string;
};

export type GetStaffSchedulingForEventResponse = {
  data: {
    [eventDateId: string]: {
      [userId: string]: Omit<GetEventDateAddStaffUserOptions['data'][number], 'conflictEvent'> & {
        conflictEvent?: {
          name: string;
          _id: string;
        };
      };
    };
  };
};

export const getStaffSchedulingForEvent = (data: GetStaffSchedulingForEventInput) => {
  return authAxiosRequest<GetStaffSchedulingForEventResponse>('/api/custom/get_staff_scheduling_for_event', { method: 'POST', data });
};

export type GetUpcomingEventsHashResponse = {
  data: {
    [resourceId: string]: {
      event: GetEventsResponse['data'][number];
      type: EventForResourceTypeEnum;
    }[];
  };
};

export type GetUpcomingEventsForTeamsHashInput = {
  teamId?: string;
  limit?: number;
};

export const getUpcomingEventsForTeamsHash = (data?: GetUpcomingEventsForTeamsHashInput) => {
  return authAxiosRequest<GetUpcomingEventsHashResponse>('/api/custom/get_upcoming_events_for_teams_hash', { method: 'POST', data });
};

export type GetUpcomingEventsForVehiclesHashInput = {
  vehicleId?: string;
  limit?: number;
};

export const getUpcomingEventsForVehiclesHash = (data?: GetUpcomingEventsForVehiclesHashInput) => {
  return authAxiosRequest<GetUpcomingEventsHashResponse>('/api/custom/get_upcoming_events_for_vehicles_hash', { method: 'POST', data });
};

export type GetUsersAvailabilitySetHashResponse = {
  data: {
    [userId: string]: {
      title: string;
      label: string;
      severity: 'error' | 'warning' | 'success';
      availabilitySlots: GetUserAvailabilityResponse['data'];
    };
  };
};

export const getUsersAvailabilitySetHash = () => {
  return authAxiosRequest<GetUsersAvailabilitySetHashResponse>('/api/custom/get_users_availability_set_hash');
};

export type DashboardEventsEvent = ResourceWithPopulated<PickFromResource<Event, 'name' | 'year' | 'iconUrl' | 'files'>, {
  team: PickFromResource<Team, 'name'>;
  teamManager: PickFromResource<User, 'name'>;
  place: PickFromResource<Place, 'address' | 'name'> & { fromStudioMatrix: PlaceMatrix };
  dates: ResourceWithPopulated<PickFromResource<EventDate, 'dateAsUtc' | 'type' | 'startTime' | 'endTime' | 'staff'>, {
    staff: ResourceWithPopulated<EventDateStaff, {
      user: PickFromResource<User, 'name'>;
    }>[];
  }>[];
  lodgingPlace: ResourceWithPopulated<PickFromResource<EventLodging['places'][number], 'place' | 'checkIn' | 'checkOut' | 'confirmationNumber' | 'phone' | 'website' | 'notes'>, {
    place: PickFromResource<Place, 'address' | 'name'>;
  }>;
}>;

export type GetDashboardDataResponse = {
  data: {
    eventsManagement?: {
      acceptanceStatusHash: Partial<Record<EventAcceptanceStatusEnum | UnknownEnum, number>>;
      applicationStatusHash: Partial<Record<EventApplicationStatusEnum | UnknownEnum, number>>;
      participationStatusHash: Partial<Record<EventParticipationStatusEnum | UnknownEnum, number>>;
      upcomingEventsCount: number;
      upcomingEventsWithIncompleteTodos: {
        _id: string;
        name: string;
        year: number;
        startDateAsUtc?: string;
        endDateAsUtc?: string;
        iconUrl?: string;
        toDos: EventToDo[];
      }[];
    };
    production?: {
      totalProduceAmount: number;
      productsNeedProduction: number;
      topThreeProducts: {
        _id: string;
        name: string;
        toProduce: number;
      }[];
      batches: GetInventoryBatchesResponse['data'];
    };
    events?: {
      feedbackReminderEvents: DashboardEventsEvent[];
      upcomingAttendEvents: DashboardEventsEvent[];
      requestedEvents: DashboardEventsEvent[];
      selfRequestedEvents: DashboardEventsEvent[];
      averageFeedbackTimeInDays: number | null;
    };
  };
};

export const getDashboardData = () => {
  return authAxiosRequest<GetDashboardDataResponse>('/api/custom/dashboard');
};