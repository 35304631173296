import { GetDashboardDataResponse } from 'api/actions';
import { Loading } from 'components';
import { useDashboardData } from 'queries';
import { createContext, useContext } from 'react';

type DashboardPageContextType = GetDashboardDataResponse['data'];

const DashboardPageContext = createContext<DashboardPageContextType>({});

export type DashboardPageContextProviderProps = {
  children: React.ReactNode;
};

export const DashboardPageContextProvider: React.FC<DashboardPageContextProviderProps> = props => {
  const { data = {}, isLoading: loading } = useDashboardData();

  if (loading) {
    return <Loading />;
  }

  return (
    <DashboardPageContext.Provider value={data}>
      {props.children}
    </DashboardPageContext.Provider>
  );
};

export const useDashboardPageContext = () => useContext(DashboardPageContext);