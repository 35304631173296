import { Box, Divider, Typography, ListItemButton } from '@mui/material';
import { DashboardEventsEvent } from 'api/actions';
import { EditableImage, CardContentLink, getMatrixDisplayText, StaffList } from 'components';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { useCurrentUser } from 'contexts';
import { eventDateEnumHelpers } from 'helpers';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DateService } from 'services';

export type EventsCardEventProps = {
  event: DashboardEventsEvent;
  eventDateActions?: (eventDateId: string, staffId: string) => React.ReactNode;
  divider?: boolean;
  additionalInfo?: React.ReactNode;
  highlight?: boolean;
  actions?: React.ReactNode;
  hideDates?: boolean;
};

export const EventsCardEvent: React.FC<EventsCardEventProps> = ({ event, divider, eventDateActions, additionalInfo, highlight, actions, hideDates = false }) => {
  const { getIsMe } = useCurrentUser();
  const navigate = useNavigate();

  return (
    <Box boxShadow={highlight ? theme => `0 6px 10px ${theme.palette.primary.main}` : undefined} p={1}>
      {divider && <Divider />}
      <Box
        mt={1}
        flex={1}
        display="grid"
        gap={1}
        gridTemplateColumns="80px 1fr"
        overflow="hidden"
        maxWidth="100%"
      >
        <EditableImage
          width="80px"
          height="80px"
          onUpload={() => {}}
          imageUrl={event.iconUrl}
          disabledEditable
        />

        <Box
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          <CardContentLink
            title={`${event.name} (${event.year})`}
            href={DYNAMIC_ROUTES.event.createLink({ eventId: event._id })}
            fontSize={18}
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          />
          <Typography
            variant="body2"
            style={{
              wordBreak: 'break-word', // Ensures text breaks within words if necessary
              whiteSpace: 'normal',    // Allows wrapping to the next line
              overflowWrap: 'break-word', // Handles long words gracefully
            }}
          >
            {event.place.address} ({getMatrixDisplayText(event.place.fromStudioMatrix)})
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            Team: {event.team?.name ?? 'Unassigned'}
            {event.teamManager && `, managed by ${event.teamManager?.name}`}
          </Typography>
        </Box>
      </Box>
      {additionalInfo}
      {!hideDates && (
        <Box
          mt={1}
          flex={1}
          display="grid"
          gap={2}
          gridTemplateColumns="repeat(auto-fill, minmax(200px, 1fr))"
        >
          {event.dates.map(date => {
            const Icon = eventDateEnumHelpers.type.getIcon(date.type);
            const staffId = date.staff.find(staff => getIsMe(staff.user._id))?._id;
            const isDateToday = DateService.dayjs().isSame(DateService.dayjsTz(date.dateAsUtc), 'day');

            return (
              <ListItemButton key={date._id} color="primary.background" sx={{ p: 0 }} onClick={() => navigate(DYNAMIC_ROUTES.eventDate.createLink({ eventId: event._id, eventDateId: date._id }))}>
                <Box borderRadius={1} bgcolor={theme => theme.palette.primary.background} p={1} width="100%" height="100%">
                  <Typography variant="body2" component="span" fontWeight={500}>
                    {DateService.dayjsTz(date.dateAsUtc).format('ddd, MMM Do')}
                  </Typography>
                &nbsp;
                  {isDateToday && <Typography variant="caption" bgcolor="primary.main" color="common.white" component="span" px={0.5} py={0.2} borderRadius={1}>Today</Typography>}
                &nbsp;
                  <Typography variant="body2" component="span" color="text.secondary">{date.startTime} - {date.endTime}</Typography>
                  <Divider />
                  <Typography variant="body2" display="flex" alignItems="center" gap={0.5} mt={1} mb={0.5}><Icon fontSize="small" /> {eventDateEnumHelpers.type.getLabel(date.type)}</Typography>
                  <Typography variant="body2" color="text.secondary">Staff: <StaffList staff={date.staff} /></Typography>
                  {(staffId && eventDateActions) && (
                    <>
                      <Divider />
                      <Box display="flex">
                        {staffId && eventDateActions?.(date._id, staffId)}
                      </Box>
                    </>
                  )}
                </Box>
              </ListItemButton>
            );
          })}
        </Box>
      )}
      {actions && <Box mt={2}>{actions}</Box>}
    </Box>
  );
};