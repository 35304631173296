import { Box, Typography } from '@mui/material';
import { EventDateStaff, User } from 'api/resources';
import { PickFromResource } from 'api/resources/resources.types';
import { userEnumHelpers } from 'helpers';
import React from 'react';

export type StaffListProps = {
  staff: Pick<Omit<EventDateStaff, 'user'> & { user: PickFromResource<User, 'name'> }, 'user' | 'status'>[];
};

export const StaffList: React.FC<StaffListProps> = ({ staff }) => {
  if (!staff.length) {
    return (
      <Typography variant="body2" fontSize="small" component="i">
        No Staff
      </Typography>
    );
  }

  return (
    <Typography
      variant="body2"
      component="span"
      overflow="auto" sx={{
        '::-webkit-scrollbar': {
          height: '2px',
        }
      }}
    >
      {
        staff.map((staff, index, arr) => (
          <React.Fragment key={staff.user._id} >
            <Box component="span" color={userEnumHelpers.staffStatus.getColor(staff.status)}>
              {staff.user.name}
            </Box>
            {index < arr.length - 1 && ', ' }
          </React.Fragment>
        ))
      }
    </Typography>
  );
};
