import { Box, Button, Divider, Typography } from '@mui/material';
import { useMemo } from 'react';
import { JumpNav, StickyStackItem } from 'components';
import { EventsOverviewUpcomingEventsCard } from './EventsOverviewUpcomingEventsCard.component';
import { DashboardPageEventsOverviewSection, dashboardPageEventsOverviewSectionEnumHelpers, DashboardPageSection } from 'pages/Dashboard/types';
import { useDashboardPageContext } from 'pages/Dashboard/utils';
import { EventsOverviewFeedbackReminderCard } from './EventsOverviewFeedbackReminderCard.component';
import { EventsOverviewStaffStatusCard } from './EventsOverviewStaffStatusCard.comonent';
import { EventDateStaffStatusEnum } from 'api/resources';

export const EventsOverview = () => {
  const { events } = useDashboardPageContext();

  const tabs = useMemo(() => {
    if (!events) {
      // typeguard
      return [];
    }
    const getBadgeContent = (sectionId: DashboardPageEventsOverviewSection) => {
      switch (sectionId) {
        case DashboardPageEventsOverviewSection.MY_EVENTS:
          return events.upcomingAttendEvents.length + events.feedbackReminderEvents.length;
        case DashboardPageEventsOverviewSection.PENDING_REQUESTS:
          return events.requestedEvents.reduce((r, { dates }) => r + dates.length, 0) + events.selfRequestedEvents.reduce((r, { dates }) => r + dates.length, 0);
        case DashboardPageEventsOverviewSection.CALENDAR:
        default:
          return undefined;
      }
    };

    return dashboardPageEventsOverviewSectionEnumHelpers.enumValues.map(sectionId => ({
      label: dashboardPageEventsOverviewSectionEnumHelpers.getLabel(sectionId),
      sectionId,
      badgeContent: getBadgeContent(sectionId),
    }));
  }, [ events ]);

  if (!events) {
    // typeguard
    return null;
  }

  return (
    <Box id={DashboardPageSection.EVENTS}>
      <StickyStackItem placement="top" order={1}>
        <Box zIndex={2}>
          <Box display="flex" justifyContent="space-between" bgcolor={theme => theme.palette.background.default}>
            <Typography variant="h6" my={1}>Events</Typography>
            <JumpNav
              initialValue={DashboardPageEventsOverviewSection.MY_EVENTS}
              offsetTop={114}
              tabs={tabs}
            />
          </Box>
          <Box mx={-4}><Divider /></Box>
        </Box>
      </StickyStackItem>

      <Box mt={2} display="flex" flexDirection="column" gap={3}>
        <Box id={DashboardPageEventsOverviewSection.MY_EVENTS} maxWidth={1300}>
          <Typography variant="h6" fontWeight={400} mb={1}>My Events</Typography>
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2} flexWrap="wrap">
            <EventsOverviewUpcomingEventsCard events={events.upcomingAttendEvents} />
            <EventsOverviewFeedbackReminderCard />
          </Box>
        </Box>

        <Box id={DashboardPageEventsOverviewSection.PENDING_REQUESTS} maxWidth={1300}>
          <Typography variant="h6" fontWeight={400} mb={1}>Pending Requests</Typography>
          <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} gap={2} flexWrap="wrap">
            <EventsOverviewStaffStatusCard
              title={totalRequests => `Event request${totalRequests === 1 ? '' : 's'} awaiting your decision`}
              events={events.requestedEvents}
              actions={(updateStaffStatus, loading) => (
                <Box display="flex" gap={0.5} flexWrap="wrap" alignItems="center" justifyContent="center" mt="5px">
                  <Button
                    color="error"
                    variant="outlined"
                    size="small"
                    sx={{ height: 25 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      updateStaffStatus(EventDateStaffStatusEnum.deniedRequest);
                    }}
                    disabled={loading}
                  >
                      Deny
                  </Button>
                  <Button
                    color="success"
                    variant="contained"
                    size="small"
                    sx={{ height: 25 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      updateStaffStatus(EventDateStaffStatusEnum.confirmed);
                    }}
                    disabled={loading}
                  >
                      Accept
                  </Button>
                </Box>
              )}
            />
            <EventsOverviewStaffStatusCard
              title={totalRequests => `Pending self request${totalRequests === 1 ? '' : 's'}`}
              events={events.selfRequestedEvents}
              actions={(updateStaffStatus, loading) => (
                <Box display="flex" gap={0.5} flexWrap="wrap" alignItems="center" justifyContent="center" mt="5px" minWidth={200}>
                  <Button
                    color="unknown"
                    variant="outlined"
                    size="small"
                    sx={{ height: 25 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      updateStaffStatus(null);
                    }}
                    disabled={loading}
                  >
                      Withdraw
                  </Button>
                  <Button
                    color="warning"
                    variant="contained"
                    size="small"
                    sx={{ height: 25 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      updateStaffStatus(EventDateStaffStatusEnum.selfRequested);
                    }}
                    disabled={loading}
                  >
                      Resend request
                  </Button>
                </Box>
              )}
            />
          </Box>
        </Box>

      </Box>
    </Box>
  );
};
