import { GetProductsResponse } from 'api/actions';

export const enum InventoryTableEditableMode {
  set = 'set',
  unary = 'unary',
  damaged = 'damaged',
  transfer = 'transfer',
}

type TransferFilters = 'showNoOutStock' | 'showInNotNeeded' | 'showOutNotOverstocked';
export type StandardInventoryTableFilters = 'onlyShowEdits' | 'showNotAvailableAtEvents' | 'onlyShowWithProduction' | TransferFilters;

export type InventoryTableTransferConfig = {
  in: string;
  out: string;
  autoFillBase?: 'in' | 'out';
};

export type InventoryEdit = {
  value: number;
  timestamp: number;
  error?: string;
  loading?: boolean;
};

export type InventoryEdits = {
  [productId: string]: {
    [teamId: string]: InventoryEdit;
  };
};

export type InventoryRow = Pick<GetProductsResponse['data'][number], '_id' | 'name' | 'availability' | 'stocks' | 'activeBaseline' | 'activeWarehouseBaseline' | 'autoBaseline' | 'autoWarehouseBaseline' | 'manualBaseline' | 'manualWarehouseBaseline'>;

export type InventoryTableLatestUpdates = {
  [productId: string]: {
    [teamId: string]: string;
  };
};

export type SaveInventoryEditsParams = {
  transferConfig: InventoryTableTransferConfig | null;
  edits: InventoryEdits;
  mode: InventoryTableEditableMode;
  isTransferOperation: boolean;
  products: InventoryRow[];
};

export type InventoryTableOnSaveFunc = (params: SaveInventoryEditsParams) => Promise<void>;

export type InventoryTableUpdateValidator<Params> = (params: Params) => string | undefined;

export type ValidateInventoryTableUnaryEditParams = {
  stock: InventoryRow['stocks'][string];
  updateQty: number;
};

export type ValidateInventoryTableSetEditParams = {
  stock: InventoryRow['stocks'][string];
  updateQty: number;
};

export type ValidateInventoryTableTransferEditParams = {
  inStock: InventoryRow['stocks'][string];
  outStock: InventoryRow['stocks'][string];
  updateQty: number;
};

export type InventoryTableCellEdit = {
  productId: string;
  teamId: string;
};

export type SetInventoryTableCellEditParams = {
  productId: string;
  teamId: string;
  newValue: string;
};

export type InventoryTableApi = {
  removeProductEdit: (productId: string) => void;
  setEdit: (params: SetInventoryTableCellEditParams) => void;
};
