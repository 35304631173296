import { InventoryBatchStatusChip, PageContentContainer, PageContentResourceHeader, PageContentResourceHeaderRenderTitleParams } from 'components';
import { useInventoryBatchOutletContext } from '../InventoryBatch.base';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useCallback, useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { InventoryBatchStatusEnum, InventoryBatchTypeEnum, UserEmployeeRoleEnum } from 'api/resources';
import { useCurrentUser } from 'contexts';

export type InventoryBatchLayoutProps = {
  actions?: React.ReactNode;
  children: React.ReactNode;
  preventReducedMarginTop?: boolean;
  isEditMode?: boolean;
};

export const InventoryBatchLayout: React.FC<InventoryBatchLayoutProps> = props => {
  const { inventoryBatch } = useInventoryBatchOutletContext();
  const { getIsMe, isAuthorizedEmployee } = useCurrentUser();
  const isOpenStep = useMemo(() => getIsMe(inventoryBatch.user._id) && inventoryBatch.status === InventoryBatchStatusEnum.open, [ getIsMe, inventoryBatch.status, inventoryBatch.user._id ]);
  const isReviewStep = useMemo(() => (!getIsMe(inventoryBatch.user._id) || isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ])) && inventoryBatch.status === InventoryBatchStatusEnum.review, [ getIsMe, inventoryBatch.status, inventoryBatch.user._id, isAuthorizedEmployee ]);
  const isManagerReviewStep = useMemo(() => isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ]) && inventoryBatch.status === InventoryBatchStatusEnum.managerReview, [ inventoryBatch.status, isAuthorizedEmployee ]);

  const description = useMemo(() => {
    if (isOpenStep) {
      let action = '';

      if (inventoryBatch.type === InventoryBatchTypeEnum.picking) {
        action = 'Picking';
      } else if (inventoryBatch.type === InventoryBatchTypeEnum.overstockPicking) {
        action = 'Picking out overstocked';
      } else if (inventoryBatch.type === InventoryBatchTypeEnum.damaged) {
        action = 'Logging damaged';
      } else if (inventoryBatch.type === InventoryBatchTypeEnum.productionDamaged) {
        action = 'Logging damaged in production';
      } else {
        action = 'Logging production';
      }

      return `${action} ${inventoryBatch.type === InventoryBatchTypeEnum.overstockPicking ? 'from' : 'for'} ${inventoryBatch.team.name}`;
    }
    if(isReviewStep) {
      let action = '';

      if (inventoryBatch.type === InventoryBatchTypeEnum.picking) {
        action = 'picking';
      } else if (inventoryBatch.type === InventoryBatchTypeEnum.overstockPicking) {
        action = 'overstock picking';
      } else if (inventoryBatch.type === InventoryBatchTypeEnum.damaged) {
        action = 'damaged';
      } else if (inventoryBatch.type === InventoryBatchTypeEnum.productionDamaged) {
        action = 'damaged in production';
      } else {
        action = 'production';
      }

      return `Review ${action} batch for ${inventoryBatch.team.name}`;
    }

    if (isManagerReviewStep) {
      let action = '';

      if (inventoryBatch.type === InventoryBatchTypeEnum.picking) {
        action = 'picking';
      } else if (inventoryBatch.type === InventoryBatchTypeEnum.overstockPicking) {
        action = 'overstock picking';
      } else if (inventoryBatch.type === InventoryBatchTypeEnum.damaged) {
        action = 'damaged';
      } else if (inventoryBatch.type === InventoryBatchTypeEnum.productionDamaged) {
        action = 'damaged in production';
      } else {
        action = 'production';
      }

      return `Manager review for ${action} batch for ${inventoryBatch.team.name}`;
    }

    return null;
  }, [ inventoryBatch.team.name, inventoryBatch.type, isManagerReviewStep, isOpenStep, isReviewStep ]);

  const userNamesDisplay = useMemo(() => {
    if (isReviewStep) {
      return (<>Opened by <b>{inventoryBatch.user.name}</b>.</>);
    }

    if (isManagerReviewStep) {
      return (
        <>
          Opened by <b>{inventoryBatch.user.name}</b>
          {inventoryBatch.reviewUser && <>, reviewed by <b>{inventoryBatch.reviewUser.name}</b></>}.
        </>
      );
    }

    return null;
  }, [ inventoryBatch.reviewUser, inventoryBatch.user.name, isManagerReviewStep, isReviewStep ]);

  const renderTitle = useCallback(({ title }: PageContentResourceHeaderRenderTitleParams) => {
    if (!description) {
      return title;
    }

    return (
      <Box display="flex" alignItems="flex-start" gap={1}>
        <Box display="flex" alignItems="center" gap={1}>
          {(isOpenStep || isReviewStep || isManagerReviewStep) && <InventoryBatchStatusChip value={inventoryBatch.status} />}
          <Box>
            {title}
            <Typography variant="h5" fontWeight={500} component="span">:</Typography>
          </Box>
        </Box>
        <Box>
          <Typography variant="h6" fontWeight={400}>{description}</Typography>
          <Typography>{userNamesDisplay}</Typography>
        </Box>
      </Box>
    );
  }, [ description, inventoryBatch.status, isManagerReviewStep, isOpenStep, isReviewStep, userNamesDisplay ]);

  return (
    <PageContentContainer
      maxWidth="xl"
      breadcrumbs={!props.isEditMode ? {
        prev: [
          { text: 'Production', link: ROUTING_CONFIG.inventoryBatches },
        ],
        current: inventoryBatch.name,
      } : undefined}
      header={(
        <PageContentResourceHeader
          title={inventoryBatch.name}
          renderTitle={renderTitle}
          pageTitle="Inventory Batch Page"
          actions={props.actions}
        />
      )}
      contentSx={props.preventReducedMarginTop ? {} : { mt: 1 }}
    >
      {props.children}
    </PageContentContainer>
  );
};