import { EditOutlined } from '@mui/icons-material';
import { Box, CircularProgress, InputAdornment, TextField, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import { KeyboardEvent } from 'react';
import { gridFilteredSortedRowIdsSelector, useGridApiContext } from '@mui/x-data-grid-pro';
import { InventoryRow } from './hooks';

export type UpdateQtyCellContentProps = {
  onChange: (value: string) => void;
  onEditCell: () => void;
  value: number | undefined;
  isTabletOrMobile: boolean;

  row: InventoryRow;
  field: string;
  error?: string;
  loading?: boolean;
  disabled?: boolean;
};

const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    fontSize: theme.typography.body2.fontSize,
    marginTop: `${theme.spacing(0.5)} !important`,
  },
}));

export const UpdateQtyCellContent: React.FC<UpdateQtyCellContentProps> = props => {
  const gridApiRef = useGridApiContext();

  const onKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const rowId = props.row._id;

    switch (e.key) {
      case 'ArrowLeft':
        // Left pressed
        const prevColumn = gridApiRef.current.getColumnIndex(props.field) - 1;
        const prevColumnId = gridApiRef.current.getAllColumns()[prevColumn]?.field;

        if (prevColumnId) {
          document.getElementById(`${rowId}-${prevColumnId}-updateQtyInput`)?.focus();
        }

        break;
      case 'ArrowRight':
        // Right pressed
        const nextColumn = gridApiRef.current.getColumnIndex(props.field) + 1;
        const nextColumnId = gridApiRef.current.getAllColumns()[nextColumn]?.field;

        if (nextColumnId) {
          document.getElementById(`${rowId}-${nextColumnId}-updateQtyInput`)?.focus();
        }

        break;
      case 'ArrowUp':
        // Up pressed
        const rows = gridFilteredSortedRowIdsSelector(gridApiRef);
        const prevRowIndex = rows.findIndex((id) => id === rowId) - 1;
        const prevRowId = rows[prevRowIndex];


        if (prevRowId) {
          document.getElementById(`${prevRowId}-${props.field}-updateQtyInput`)?.focus();
        }

        break;
      case 'ArrowDown':
        // Down pressed
        const rows1 = gridFilteredSortedRowIdsSelector(gridApiRef);
        const nextRowIndex = rows1.findIndex((id) => id === rowId) + 1;
        const nextRowId = rows1[nextRowIndex];

        if (nextRowId) {
          document.getElementById(`${nextRowId}-${props.field}-updateQtyInput`)?.focus();
        }

        break;
    }
  };

  const disabled = props.disabled || props.loading;

  return (
    <ErrorTooltip title={props.error}>
      <Box width="84px" position="relative" display="flex" alignItems="center">
        {props.loading && (
          <Box position="absolute" top={0} left={0} height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress size={20} />
          </Box>
        )}
        <TextField
          id={`${props.row._id}-${props.field}-updateQtyInput`}
          onKeyDown={onKeyDown}
          autoComplete="off"
          autoCorrect="off"
          error={!!props.error}
          value={props.value ?? ''}
          onChange={(e) => props.onChange(e.target.value)}
          InputProps={{ endAdornment: <InputAdornment position="end"><EditOutlined fontSize="small" color={!!props.error ? 'error' : undefined} /></InputAdornment>, sx: { pr: 1 } }}
          disabled={props.isTabletOrMobile || disabled}
          sx={{
            opacity: props.loading ? 0.25 : undefined,

            '& input[disabled]': !disabled ? {
              color: theme => theme.palette.text.primary,
              '-webkit-text-fill-color': 'unset',
            } : undefined,

            '& fieldset': {
              borderColor: props.error ? theme => `${theme.palette.error.main} !important` : undefined
            },
            background: theme => props.error ? theme.palette.error.background : theme.palette.background.paper
          }}
        />
        {props.isTabletOrMobile && <Box position="absolute" top={0} left={0} width="100%" height="100%" sx={{ cursor: !disabled ? 'pointer' : undefined }} onClick={() => !disabled && props.onEditCell()} />}
      </Box>
    </ErrorTooltip>
  );
};