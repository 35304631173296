import { Card, Box, Typography, Divider } from '@mui/material';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { DashboardEventsEvent, removeStaffFromEventDate, updateEventDateStaff } from 'api/actions';
import { EventDateStaffStatusEnum } from 'api/resources';
import { useAlertSnackbar } from 'components';
import { QUERY_KEY } from 'queries/query-keys';
import { EventsCardEvent } from './EventsCardEvent.component';

export type EventsOverviewStaffStatusCardProps = {
  title: ((totalRequests: number) => string) | string;
  events: DashboardEventsEvent[];
  actions?: (updateStaffStatus: (status: EventDateStaffStatusEnum | null) => void, loading: boolean) => React.ReactNode;
};

export const EventsOverviewStaffStatusCard: React.FC<EventsOverviewStaffStatusCardProps> = ({ events, actions, title }) => {
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();
  const updateMutation = useMutation({
    mutationFn: ({ eventDateId, staffId, status }: {
      eventDateId: string;
      staffId: string;
      status: EventDateStaffStatusEnum;
    }) => updateEventDateStaff(eventDateId, staffId, { status }),
    onSuccess: async (_, updates) => {
      if (updates.status === EventDateStaffStatusEnum.confirmed) {
        snackbar.success('Request accepted');
      } else {
        snackbar.success('Request denied');
      }

      await queryClient.invalidateQueries(QUERY_KEY.DASHBOARD);
    },
    onError: () => {
      snackbar.error('Staff status could not be updated');
    }
  });
  const deleteMutation = useMutation({
    mutationFn: ({ eventDateId, staffId }: {eventDateId: string; staffId: string}) => removeStaffFromEventDate(eventDateId, staffId),
    onSuccess: async () => {
      snackbar.success('Request withdrawn');

      await queryClient.invalidateQueries(QUERY_KEY.DASHBOARD);
    },
    onError: () => {
      snackbar.error('Request could not be withdrawn');
    }
  });

  const updateStaffStatus = (status: EventDateStaffStatusEnum | null, eventDateId: string, staffId: string) => {
    if (status === null) {
      deleteMutation.mutate({ eventDateId, staffId });
    } else {
      updateMutation.mutate({ eventDateId, staffId, status });
    }
  };

  const totalRequests = events.reduce((r, event) => r + event.dates.length, 0);

  return (
    <Card variant="outlined" sx={{ flex: 1, borderRadius: 0 }}>
      <Box m={2}>
        <Typography fontWeight={500} variant="h5">{totalRequests}</Typography>
        <Typography variant="body2" color="text.secondary" mb={3}>{typeof title === 'function' ? title(totalRequests) : title}</Typography>
        <Divider />
        {events.map((event, index) => {
          return (
            <Box key={event._id} my={3}>
              <EventsCardEvent
                event={event}
                divider={!!index}
                eventDateActions={(eventDateId: string, staffId: string) => actions?.((status) => updateStaffStatus(status, eventDateId, staffId), updateMutation.isLoading || deleteMutation.isLoading)}
              />
            </Box>
          );
        })}
        <Divider />
      </Box>
    </Card>
  );
};