import { Box } from '@mui/material';
import { UserEmployeeRoleEnum } from 'api/resources';
import { JumpNav, PageContentContainer, PageContentHeader } from 'components';
import { useCurrentUser } from 'contexts';
import { useMemo } from 'react';
import { EventsManagementOverview } from './components/EventsManagementOverview.component';
import { EventsOverview } from './components/EventsOverview';
import { ProductionOverview } from './components/ProductionOverview.component';
import { DashboardPageSection } from './types';
import { DashboardPageContextProvider } from './utils';

export const Dashboard = () => {
  const { isAuthorizedEmployee } = useCurrentUser();

  const initialTab = useMemo(() => {
    if (isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ], false)) {
      return DashboardPageSection.EVENTS_MANAGEMENT;
    }
    if (isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ], false)) {
      return DashboardPageSection.PRODUCTION;
    }
    if (isAuthorizedEmployee([ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ], false)) {
      return DashboardPageSection.EVENTS;
    }

    return null;
  }, [ isAuthorizedEmployee ]);

  const tabs = useMemo(() => initialTab && (
    <JumpNav
      initialValue={initialTab}
      offsetTop={64}
      tabs={[
        { label: 'Events Management', sectionId: DashboardPageSection.EVENTS_MANAGEMENT, hidden: !isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ], false) },
        { label: 'Events', sectionId: DashboardPageSection.EVENTS, hidden: !isAuthorizedEmployee([ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ], false) },
        { label: 'Production', sectionId: DashboardPageSection.PRODUCTION, hidden: !isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ], false) },
      ]}
    />
  ), [ initialTab, isAuthorizedEmployee ]);

  return (
    <PageContentContainer
      header={(
        <PageContentHeader
          title="Dashboard"
          sticky
          tabs={tabs}
        />
      )}
    >
      <DashboardPageContextProvider>
        <Box display="flex" flexDirection="column" gap={5}>
          {isAuthorizedEmployee([ UserEmployeeRoleEnum.eventManager ], false) && <EventsManagementOverview />}
          {isAuthorizedEmployee([ UserEmployeeRoleEnum.teamLead, UserEmployeeRoleEnum.teamMember ], false) && <EventsOverview />}
          {isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ], false) && <ProductionOverview />}
        </Box>
      </DashboardPageContextProvider>
    </PageContentContainer>
  );
};

