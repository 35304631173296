import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY } from './query-keys';
import { getDashboardData } from 'api/actions';

export const useDashboardData = () => {
  return useQuery(QUERY_KEY.DASHBOARD, async () => {
    const response = await getDashboardData();

    return response.data;
  });
};