import { ThumbUpAltOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

export type GoodJobProps = {
  description: string;
};

export const GoodJob: React.FC<GoodJobProps> = props => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      height="100%"
      pb="20%"
    >
      <ThumbUpAltOutlined sx={{ fontSize: 180, color: theme => theme.palette.success.main, mb: 2 }} />
      <Box display="flex" alignItems="center" flexDirection="column">
        <Typography variant="h5">Great work!</Typography>
        <Typography color="text.secondary">{props.description}</Typography>
      </Box>
    </Box>
  );
};