import { Box, Divider, List, Stack, Typography } from '@mui/material';
import { CardContentContainer, CardContentLink, EditableImage, StickyStackItem, ToDoListItem } from 'components';
import React from 'react';
import { DashboardPageSection } from '../types';
import { DYNAMIC_ROUTES } from 'constants/routing-config';
import { eventEnumHelpers } from 'helpers';
import { EnumHelpers } from 'helpers/enums/types';
import { DateService } from 'services';
import { QUERY_KEY } from 'queries/query-keys';
import { useDashboardPageContext } from '../utils';
import { GoodJob } from './GoodJob.component';

export const EventsManagementOverview = () => {
  const { eventsManagement: data } = useDashboardPageContext();

  if (!data) {
    // typeguard
    return null;
  }

  const todosCount = data.upcomingEventsWithIncompleteTodos.reduce((acc, toDo) => acc + toDo.toDos.length, 0);

  return (
    <Box id={DashboardPageSection.EVENTS_MANAGEMENT}>
      <StickyStackItem placement="top" order={1}>
        <Box zIndex={2}>
          <Box display="flex" justifyContent="space-between" bgcolor={theme => theme.palette.background.default}>
            <Typography variant="h6" my={1}>Events Management</Typography>
          </Box>
          <Box mx={-4}><Divider /></Box>
        </Box>
      </StickyStackItem>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={2} mt={2}>
        <CardContentContainer sx={{ borderRadius: 0, flex: 1 }}>
          <Typography fontWeight={500} variant="h5" mt={1} ml={1}>{data.upcomingEventsCount}</Typography>
          <Typography variant="body2" color="text.secondary" ml={1}>Total upcoming events</Typography>


          <Typography color="text.secondary" mb={2} mt={5}>Participation Status</Typography>
          <Box display="flex" flexWrap="wrap" gap={3}>
            {eventEnumHelpers.participationStatus.enumValues.map(status => {
              return <EventsStatusesOverviewNode key={status} value={status} helpers={eventEnumHelpers.participationStatus} count={data.participationStatusHash[status] ?? 0} totalEvents={data.upcomingEventsCount} />;
            })}
          </Box>

          <Typography color="text.secondary" mb={2} mt={5}>Application Status</Typography>
          <Box display="flex" flexWrap="wrap" gap={3}>
            {eventEnumHelpers.applicationStatus.enumValues.map(status => {
              return <EventsStatusesOverviewNode key={status} value={status} helpers={eventEnumHelpers.applicationStatus} count={data.applicationStatusHash[status] ?? 0} totalEvents={data.upcomingEventsCount} />;
            })}
          </Box>

          <Typography color="text.secondary" mb={2} mt={5}>Acceptance Status</Typography>
          <Box display="flex" flexWrap="wrap" gap={3}>
            {eventEnumHelpers.acceptanceStatus.enumValues.map(status => {
              return <EventsStatusesOverviewNode key={status} value={status} helpers={eventEnumHelpers.acceptanceStatus} count={data.acceptanceStatusHash[status] ?? 0} totalEvents={data.upcomingEventsCount} />;
            })}
          </Box>
        </CardContentContainer>

        <CardContentContainer sx={{ borderRadius: 0, flex: 1 }}>
          <Typography fontWeight={500} variant="h5" mt={1} ml={1}>{todosCount}</Typography>
          <Typography variant="body2" color="text.secondary" ml={1} mb={3}>To-do{todosCount === 1 ? '' : 's'}</Typography>

          {todosCount ?  data.upcomingEventsWithIncompleteTodos.map((event, index, arr) => {
            return (
              <React.Fragment key={event._id}>
                <Box display="flex" justifyContent="space-between" flexDirection={{ xs: 'column', 'lg': 'row' }}>
                  <Box display="flex" gap={1} flex={1}>
                    <EditableImage
                      width={90}
                      height={90}
                      imageUrl={event.iconUrl}
                      disabledEditable
                      onUpload={() => {}}
                    />
                    <Box>
                      <CardContentLink
                        title={`${event.name} (${event.year})`}
                        href={DYNAMIC_ROUTES.event.createLink({ eventId: event._id })}
                      />
                      <Typography>{DateService.getFormattedDateRange(event.startDateAsUtc, event.endDateAsUtc)}</Typography>
                    </Box>
                  </Box>
                  <List sx={{ '& .MuiListItem-root': { bgcolor: theme => theme.palette.primary.background, borderRadius: 1, mb: 1, p: 1 } }}>
                    {event.toDos.map((toDo) => (
                      <ToDoListItem
                        checkboxPosition="end"
                        key={toDo._id}
                        event={event}
                        toDo={toDo}
                        invalidateQueriesHandler={(queryClient) => queryClient.invalidateQueries({ queryKey: QUERY_KEY.DASHBOARD })}
                      />
                    ))}
                  </List>
                </Box>
                {index < arr.length - 1 && <Box my={2}><Divider /></Box>}
              </React.Fragment>
            );
          }) : <GoodJob description="You have no incomplete to-dos" />}
        </CardContentContainer>
      </Box>
    </Box>
  );
};

// Statuses Overview

type EventsStatusesOverviewNodeProps<StatusEnum extends string> = {
  value: StatusEnum;
  helpers: EnumHelpers<StatusEnum>;
  count: number;
  totalEvents: number;
};

const EventsStatusesOverviewNode = <StatusEnum extends string>(props: EventsStatusesOverviewNodeProps<StatusEnum>) => {
  const percentage = Math.round(props.count / props.totalEvents * 100);

  return (
    <Box
      sx={theme => ({
        width: 120,
        height: 110,
        borderRadius: 2,
        borderTop: `12px solid ${theme.palette[props.helpers.getColor(props.value)].main}`,
        background: theme.palette.grey[200],
      })}
    >
      <Stack alignItems="center" justifyContent="space-between" gap={1} my={1}>
        <Typography variant="body2">{props.helpers.getLabel(props.value)}</Typography>
        <Typography fontWeight={600}>{percentage}%</Typography>
        <Typography variant="body2" color="text.secondary">{props.count} events</Typography>
      </Stack>
    </Box>
  );
};