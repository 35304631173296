import { Box, Button, Card, Divider, Grid, ListItemButton, Typography } from '@mui/material';
import { CardContentContainer, InventoryBatchesTable, ProductionTimelineChart, StickyStackItem } from 'components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DashboardPageSection } from '../types';
import { DYNAMIC_ROUTES, ROUTING_CONFIG } from 'constants/routing-config';
import { useDashboardPageContext } from '../utils';
import { InventoryBatchStatusEnum, UserEmployeeRoleEnum } from 'api/resources';
import { useCurrentUser } from 'contexts';
import { DateService } from 'services';

export const ProductionOverview = () => {
  const navigate = useNavigate();
  const { isAuthorizedEmployee } = useCurrentUser();
  const { production: data } = useDashboardPageContext();

  if (!data) {
    // typeguard
    return null;
  }

  const totalOpenInventoryBatches = data.batches.filter(batch => batch.status === InventoryBatchStatusEnum.open).length;
  const totalReviewInventoryBatches = data.batches.filter(batch => batch.status === InventoryBatchStatusEnum.review).length;
  const totalManagerReviewInventoryBatches = data.batches.filter(batch => batch.status === InventoryBatchStatusEnum.managerReview).length;

  return (
    <Box id={DashboardPageSection.PRODUCTION}>
      <StickyStackItem placement="top" order={1}>
        <Box zIndex={2}>
          <Box display="flex" justifyContent="space-between" bgcolor={theme => theme.palette.background.default}>
            <Typography variant="h6" my={1}>Production</Typography>
          </Box>
          <Box mx={-4}><Divider /></Box>
        </Box>
      </StickyStackItem>

      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} lg={6}>
          <Card variant="outlined" sx={{ borderRadius: 0 }}>
            <Box m={3}>
              <Box display="flex" gap={7}>
                <Box>
                  <Typography fontWeight={500} variant="h5" display="flex" alignItems="flex-end" gap={0.5}>{data.totalProduceAmount} <Typography mb={0.1}>Pieces</Typography></Typography>
                  <Typography variant="body2" color="text.secondary">Total to produce</Typography>
                </Box>
                <Box>
                  <Typography fontWeight={500} variant="h5" display="flex" alignItems="flex-end" gap={0.5}>{data.productsNeedProduction} <Typography mb={0.1}>Designs</Typography></Typography>
                  <Typography variant="body2" color="text.secondary">Need production (at least 1 piece)</Typography>
                </Box>
              </Box>
              <Box my={3}  mx={-3} bgcolor="primary.background">
                <Divider />
                <Box my={3} mx={2}>
                  <Typography variant="h6" fontWeight={400} color="primary.dark">NEXT 3</Typography>
                  <Typography variant="body2" color="text.secondary">Designs to run</Typography>
                  <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" gap={1} mt={2}>
                    {data.topThreeProducts.map(product => {
                      return (
                        <Box
                          sx={{
                            flex: 1,
                            backgroundColor: 'primary.background',
                            border: '1px solid',
                            borderColor: 'primary.main',
                            borderRadius: 1,
                          }}
                          key={product._id}
                        >
                          <ListItemButton onClick={() => navigate(DYNAMIC_ROUTES.product.createLink({ productId: product._id }))}>
                            <Box
                              sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                py: 1,
                              }}
                            >
                              <Typography variant="h5" color="primary.main" fontWeight={500}>
                                <Typography variant="caption" component="span" color="text.secondary">produce</Typography>
                          &nbsp;{product.toProduce}&nbsp;
                                <Typography variant="caption" component="span" color="text.secondary">total</Typography>
                              </Typography>
                              <Typography variant="body2">{product.name}</Typography>
                            </Box>
                          </ListItemButton>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <Divider />
              </Box>
              <Box display="flex" gap={1} mb={2}>
                <Button variant="outlined" color="primary" fullWidth onClick={() => navigate(ROUTING_CONFIG.productionSchedule)}>Go to Production Page</Button>
                <Button variant="contained" color="primary" fullWidth onClick={() => navigate(ROUTING_CONFIG.inventoryBatches)}>Go to Inventory Batches Page</Button>
              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} lg={6}>
          <CardContentContainer sx={{ borderRadius: 0, height: 410 }}>
            <Box display="flex" gap={7} mb={4}>
              {isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ]) && (
                <Box>
                  <Typography fontWeight={500} variant="h5">{totalOpenInventoryBatches}</Typography>
                  <Typography variant="body2" color="text.secondary">Batch{totalOpenInventoryBatches === 1 ? '' : 'es'} open</Typography>
                </Box>
              )}
              <Box>
                <Typography fontWeight={500} variant="h5">{totalReviewInventoryBatches}</Typography>
                <Typography variant="body2" color="text.secondary">Batch{totalReviewInventoryBatches === 1 ? '' : 'es'} need{totalReviewInventoryBatches === 1 ? 's' : ''} review</Typography>
              </Box>
              {isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ]) && (
                <Box>
                  <Typography fontWeight={500} variant="h5">{totalManagerReviewInventoryBatches}</Typography>
                  <Typography variant="body2" color="text.secondary">Batch{totalManagerReviewInventoryBatches === 1 ? '' : 'es'} need{totalManagerReviewInventoryBatches === 1 ? 's' : ''} manager review</Typography>
                </Box>
              )}
            </Box>
            <Box mx={-2} height={310}>
              <InventoryBatchesTable hideActions inventoryBatches={data.batches} />
            </Box>
          </CardContentContainer>
        </Grid>

        {isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager ]) && (
          <Grid item xs={12}>
            <ProductionTimelineChart
              dateRange={{ start: DateService.dayjs().subtract(2, 'weeks'), end: DateService.dayjs() }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
